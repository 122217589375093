@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
}

.slide-in-left-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-in-left-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.header-bg {
  background: url(/static/media/SWT-Configurator-Header.3b2bcc24.jpg) center bottom / cover no-repeat;
  height: 40vh;
  width: 100vw;
}

.header-logo {
  height: 5vh;
}

.mobile-logo {
  padding-left: 60%;
}

.main-header-content {
  padding-top: 1em;
  font-weight: lighter;
}

.thank-you-content {
  padding-top: 0;
}

.main-header-content-size {
  font-size: 5vw;
  text-align: center;
}

.main-header-content-size-tools {
  font-size: 7vw;
  text-align: center;
}

.thank-you-bg {
  background: url(/static/media/Configurator3.8b3ed6f5.jpg) center center / cover;
  height: 25vh;
}

.thank-you-title {
  font-size: 8vh;
  color: white;
}

.m-header {
  margin-top: 4rem;
}

/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 992px) {
  .main-header-content {
    padding-top: 10%;
    padding-left: 10%;
    font-weight: lighter;
  }

  .thank-you-content {
    padding-top: 7%;
  }

  .header-logo {
    margin-top: 60%;
    height: 8vh;
    margin-right: 5%;
  }

  .main-header-content-size {
    text-align: left;
    font-size: 2.8vw;
  }

  .main-header-content-size-tools {
    font-size: 4vw;
  }

  .mobile-logo {
    padding-left: 0;
  }
}

.form-bg {
  background-color: rgb(212, 237, 252);
}

.btn-rnd {
  border-radius: 50px;
}

